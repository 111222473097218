var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list-view',{attrs:{"server-side":"","hideCreate":"","app":"audit","model":"auditheader","api-url":"audit/audit-header/","title":_vm.$t('menu.audit'),"editTo":{ name: 'auditEdit' },"headers":_vm.headers},scopedSlots:_vm._u([{key:"prefix-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.openDialogAudit}},[_vm._v(" "+_vm._s(_vm.$t('btn.create'))+" ")])]},proxy:true},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"1500"},model:{value:(_vm.dialogAudit),callback:function ($$v) {_vm.dialogAudit=$$v},expression:"dialogAudit"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-4"},[_c('v-toolbar',{attrs:{"dense":"","color":"primary white--text"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('menu.createAudit'))+" ")])],1),_c('app-form',{attrs:{"app":"audit","model":"auditheader","api-url":"audit/audit-header/","title":_vm.$t('menu.audit'),"prepare-data":_vm.prepareData,"state":_vm.formData.state,"hideSave":"","hideCancel":""},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","lg":"6"}},[_c('app-input',{attrs:{"name":"company","rules":"required","type":"select-server","label":_vm.$t('fields.selectCompany'),"binds":{
                      apiUrl: 'contact/contact/?active=true'
                    }},on:{"input":_vm.getItem},model:{value:(_vm.formData.contact_id),callback:function ($$v) {_vm.$set(_vm.formData, "contact_id", $$v)},expression:"formData.contact_id"}})],1),_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","lg":"6"}},[_c('app-input',{attrs:{"name":"auditPlan","rules":"required","type":"select-server","label":_vm.$t('fields.selectAuditPlan'),"disabled":!Boolean(_vm.formData.contact_id),"binds":{
                      apiUrl: _vm.auditPlanUrl
                    }},model:{value:(_vm.formData.audit_plan_id),callback:function ($$v) {_vm.$set(_vm.formData, "audit_plan_id", $$v)},expression:"formData.audit_plan_id"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","lg":"6"}},[_c('app-input',{attrs:{"name":"trialBalance","type":"select-server","label":_vm.$t('fields.selectTrialBalance'),"disabled":!Boolean(_vm.formData.contact_id),"binds":{
                      apiUrl: _vm.trialBalanceUrl
                    }},model:{value:(_vm.formData.trial_balance_header_id),callback:function ($$v) {_vm.$set(_vm.formData, "trial_balance_header_id", $$v)},expression:"formData.trial_balance_header_id"}})],1)],1)]],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialogAudit}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('btn.create'))+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }